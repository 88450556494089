<template>
    <div class="version-app">
        <h2>Current app version is: <span>{{ version }}</span></h2>
        
        <div class="new-version">
            <md-field>
                <label>New version</label>
                <md-input placeholder="x.x.x" v-model="version" />
                <md-button class="md-raised md-primary" @click="setVersion">Change version</md-button>
            </md-field>
        </div>
    </div>
</template>

<script>
import {api} from '@/utils/api';

export default {
    name: 'Version',
    data() {
        return {
            version: '',
        }
    },
    created() {
        api.call(null, 'get', '/api/latest-version/').then(res => {
            this.version = res.version
        })
    },
    methods: {
        setVersion() {
            api.call(null, 'get', `/api/set-version?v=${this.version}&key=b5b6d1757c82a6c83817dde01f8cfa57`).then(() => {
                api.call(null, 'get', '/api/latest-version/').then(res => {
                    this.version = res.version
                })
            })
        }
    }
}
</script>

<style lang="scss">
.version-app {
    margin-right: 40px;
    min-height: 400px;
    h2 {
        font-weight: normal;
        span {
            font-weight: bold;
        }
    }
    .new-version {
        max-width: 400px;

        .md-field {
            align-items: center;
        }
    }
}
</style>